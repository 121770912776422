import * as React from "react"
import { Hero } from "../components/hero"
import { Service } from "../components/service"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

interface ServicesProps {
  pageContext: any
  data: any
}

export default function ServicesPage({ data, pageContext }: ServicesProps) {
  const { i18n } = pageContext
  const allServices = data.allDatoCmsService.nodes
  return (
    <>
      <Hero title={i18n.services.title}>
        <StaticImage
          className="!absolute z-0 w-full h-full"
          src="../assets/images/banner_heidi.jpg"
          objectPosition="78% 50%"
          alt=""
          quality={95}
        />
      </Hero>
      <div className="my-10 out-wrapper">
        {allServices.map((service, i) => {
          return (
            <Service service={service} index={i} i18n={i18n} key={service.id} />
          )
        })}
      </div>
    </>
  )
}

export const query = graphql`
  query ($lang: String) {
    allDatoCmsService(
      filter: { locale: { eq: $lang } }
      sort: { fields: order }
    ) {
      nodes {
        id
        title
        slug
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          alt
          gatsbyImageData(aspectRatio: 1)
        }
      }
    }
  }
`
