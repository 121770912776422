import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { ButtonLink } from "./button-link"
interface ServiceProps {
  index: number
  service: Service
  i18n: any
  classes?: string
}

interface Service {
  id: string
  title: string
  descriptionNode: any
  image: any
  slug?: string
  alt?: string
}

export function Service({ index, service, classes, i18n }: ServiceProps) {
  return (
    <div
      className={`${
        index % 2 === 0 ? "border-l-primary-200" : "border-secondary-200"
      } relative pb-4 border-l-2 top-4 left-6`}
    >
      <div
        className={`${
          index % 2 === 0 ? "border-b-primary-200" : "border-secondary-200"
        } relative pb-8 pl-16 my-10 border-b-2  -left-8`}
      >
        <h2
          className="mb-6 text-3xl font-bold"
          key={service.id}
          id={service.id}
        >
          {service.title}
        </h2>
        <div className="flex flex-col md:flex-row">
          <div className="mb-8 md:mb-0 max-w-fit w-[48rem]">
            <GatsbyImage
              className="rounded-lg drop-shadow-lg"
              objectFit="contain"
              alt={service.image.alt || ""}
              image={service.image.gatsbyImageData}
              loading="lazy"
            />
          </div>
          <div>
            <div
              className="md:ml-8"
              dangerouslySetInnerHTML={{
                __html: service.descriptionNode.childMarkdownRemark.html,
              }}
            ></div>
            {service.slug === "contact" ? (
              <ButtonLink
                classes="block mx-auto mt-8 max-w-fit"
                slug={i18n.slugs.contact}
                text={i18n.common.bigLinks.contact}
              />
            ) : (
              <ButtonLink
                classes="block mx-auto mt-8 max-w-fit"
                slug="https://goo.gl/forms/9gtIuK2UJ6Ayys3b2"
                text={i18n.services.requestQuote}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
